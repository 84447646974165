import React from "react";
import { Fade } from "react-reveal";
import { HashLink as Link } from "react-router-hash-link";
import whiteLogo from "../../assets/images/logo.png";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "+972545859547";
    const textMessage = encodeURIComponent(
      "היי,הייתי באתר שלכם.אשמח לשמוע פרטים 😊"
    );
    const whatsappUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${textMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
        <div className="container">
          <div className="row padding-bottom">
            <Fade fade delay={100}>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-about">
                  <div className="footer-logo sc-mb-25">
                    <Link to="#">
                      <img src={whiteLogo} alt="FooterLogo" />
                    </Link>
                  </div>
                  <p className="footer-des">תוכנה לניהול עולם היופי והטיפוח</p>
                  {/* <ul className="about-icon">
                    <li>
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-facebook-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.twitter.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-twitter-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-linkedin-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-instagram-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.pinterest.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="ri-pinterest-fill"></i>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </Fade>
            <Fade fade delay={200}>
              <div className="col-lg-3 col-sm-6 sc-xs-mt-40">
                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0 sc-md-mb-30 sc-sm-mb-0">
                  <h4 className="footer-title white-color sc-md-mb-15">
                    החברה
                  </h4>
                  <ul className="footer-menu-list">
                    <li>
                      <Link to="/about#">אודות</Link>
                    </li>
                    <li>
                      <Link to="/PrivacyPolicy#">מדיניות הפרטיות</Link>
                    </li>

                    {/* <li>
                      <Link to="/contact#">יצירת קשר</Link>
                    </li> */}
                    <li>
                      <Link to="/TermOfUse#">תנאי שימוש</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            {/* <Fade fade delay={300}>
              <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0"></div>
              </div>
            </Fade> */}
            <Fade fade delay={400}>
              <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                  {/* <h4 className="footer-title white-color sc-md-mb-15">
                    יצירת קשר
                  </h4> */}
                  <ul className="footer-menu-list">
                    <li>
                      <i className="ri-mail-fill"></i>
                      <a href="mailto:support@beautyplus.cloud">
                        support@beautyplus.cloud
                      </a>
                    </li>
                    <li>
                      <i className="ri-phone-fill"></i>
                      <a href="tel:(000)123456789">0545859547</a>
                    </li>
                    <li className="footer-map">
                      <i className="ri-clock-line"></i>
                      <span>
                        שעות פעילות א' - ה' : 10:00 עד- 18:00 שישי שבת וחגים :
                        סגור
                      </span>
                    </li>
                    <li>
                      <button
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "15px",
                          fontWeight: "bold",
                          marginLeft: "25px",
                          display: "flex",
                        }}
                        onClick={handleWhatsAppClick}
                      >
                        <FaWhatsapp style={{ marginRight: "5px" }} />
                        צרו קשר בוואטסאפ
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="border-top"></div>
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text text-center">
                  <p>
                    &copy; {new Date().getFullYear()}
                    <Link> א.מ שירותי מחשוב - BeautyPlus, </Link> כל הזכויות
                    שמורות
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
