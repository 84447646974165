import { HashLink as Link } from "react-router-hash-link";
import React from "react";

const MenuItems = (props) => {
  return (
    <>
      {/* to="/contact# */}
      <li>
        <Link className="active" smooth to="/price">
          מחירון
        </Link>
      </li>

      {/* <li>
        <Link className="active" to="/contact#">
          יצירת קשר
        </Link>
      </li> */}
      <li>
        <Link className="active" to="/PrivacyPolicy#">
          מדיניות הפרטיות
        </Link>
      </li>
      {/* <li>
        <Link className="active" to="/faq#">
          שאלות ותשובות
        </Link>
      </li> */}
      <li>
        <Link className="active" to="/about#">
          אודות
        </Link>
      </li>
      <li className="current-menu-item">
        <Link className="active" to="/#">
          בית
        </Link>
      </li>
    </>
  );
};

export default MenuItems;
