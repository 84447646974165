import React, { useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import blogImg1 from "../../assets/images/blog/manicure.jpg";
import blogImg2 from "../../assets/images/blog/spa1.jpg";
import blogImg3 from "../../assets/images/blog/eyebrows1.jpg";
import blogImg4 from "../../assets/images/blog/laser1.jpg";
import blogImg5 from "../../assets/images/blog/clinic 1.jpg";
import blogImg6 from "../../assets/images/blog/tan1.jpeg";
import blogImg7 from "../../assets/images/blog/makeup1.jpeg";
import blogImg8 from "../../assets/images/blog/masage1.jpeg";
import blogImg9 from "../../assets/images/blog/calot1.jpeg";

const LatestBlog = () => {
  const carouselRef = useRef(null);

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  return (
    <>
      <section className="sc-blog-area sc-pt-125 sc-md-pt-65 sc-sm-mb-30">
        <div className="container">
          <div className="sc-heading-area sc-mb-55 sc-md-mb-45 text-center">
            <h2 className="title">סוגי עסקים שהמערכת מתאימה להם 👇</h2>
          </div>
          <div className="blog-item-area slider-item-area sc-slick-slide sc-slick-slides">
            <div className="latest-blog-carousel-wrapper">
              <Carousel
                ref={carouselRef}
                className="latest-blog-carousel"
                infinite={true}
                draggable={false}
                arrows={true}
                pauseOnHover={true}
                slidesToSlide={1}
                swipeable={true}
                autoPlay={true}
                autoPlaySpeed={2200}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 992,
                    },
                    items: 3,
                  },
                  tablet: {
                    breakpoint: {
                      max: 991,
                      min: 577,
                    },
                    items: 2,
                  },
                  mobile: {
                    breakpoint: {
                      max: 576,
                      min: 1,
                    },
                    items: 1,
                  },
                }}
              >
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg1} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>מניקור - פדיקור</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg2} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>ספא ובריאות</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg3} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>עיצוב גבות</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg4} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>הסרת שיער בלייזר</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg5} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>קליניקות</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg6} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>ניהול מכון שיזוף</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg7} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>איפור / איפור קבוע</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg8} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>מסא׳ז</span>
                    </h3>
                  </div>
                </div>
                <div className="blog-item text-center">
                  <div className="blog-image">
                    <Link to="/blog-details#">
                      <img src={blogImg9} width="950" height="250" alt="Blog" />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-list-menu"></div>
                    <h3 className="blog-title sc-mt-20">
                      <span>סלון כלות</span>
                    </h3>
                  </div>
                </div>
              </Carousel>
              <div
                className="custom-arrows"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button className="prev" onClick={prevSlide} style={arrowStyle}>
                  <FaChevronLeft />
                </button>
                <button className="next" onClick={nextSlide} style={arrowStyle}>
                  <FaChevronRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const arrowStyle = {
  backgroundColor: "black",
  color: "#fff",
  border: "none",
  borderRadius: "30%",
  padding: "10px",
  margin: "0 10px",
  cursor: "pointer",
  outline: "none",
};

export default LatestBlog;
