import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div dir="rtl">
        <h1 style={{ color: "dodgerblue", fontSize: "40px" }}>
          מדיניות הפרטיות – בתי עסק
        </h1>
        <p>
          מדיניות פרטיות זו מתארת כיצד חברת ביוטיפלוס לעסקים (להלן: "ביוטיפלוס")
          אוספת מידע על המבקרים והמשתמשים בשירותים המוצעים באתר ביוטיפלוס המצוי
          בכתובת האינטרנט https://beautyplus.cloud/ (להלן: "האתר") ובתוכנה
          לניהול עסקים קטנים הידועה בשם "ביוטיפלוס" (להלן: "המערכת") בין בתשלום
          ובין אם לאו, בין באמצעות האתר ובין באמצעות אמצעים טכנולוגיים אחרים
          כדוגמת אתר מותאם ואפליקציית לסמארטפון. מטרת מדיניות הפרטיות היא להסביר
          איזה שימוש עושה ביוטיפלוס במידע הפרטי הנמסר על ידך באמצעות השימוש באתר
          ובמערכת. ביוטיפלוס מחויבת להגן על המידע האישי שלך (כהגדרתו להלן) באופן
          העולה בקנה אחד עם חוקי הגנת המידע והפרטיות התקפים. אנא קרא היטב את
          מדיניות הפרטיות שלהלן וודא כי הנך מבין איזה שימוש עושה ביוטיפלוס במידע
          הפרטי שלך קודם לרישום ולהתחלת השימוש באתר ובמערכת. תנאי מדיניות
          הפרטיות המפורטים בלשון זכר הינם לשם נוחיות בלבד והיא מיועדת לשני
          המינים כאחד.
        </p>
        <p>
          1. המידע שנאסף באמצעות השימוש במערכת ובאתר המידע האישי שנאסף על ידי
          ביוטיפלוס ("מידע אישי") מורכב בעיקר מפרטים ליצירת קשר (לדוגמה, כתובת
          דואר אלקטרוני, מספר טלפון וכדומה), פרטי חיוב (שם, כתובת לחיוב פיזי,
          אמצעי תשלום ופרטי עסקה) אשר נאספים רק ממשתמשים עם שירותים בתשלום,
          פרטים בנוגע לחשבונות צד שלישי מחוברים (כגון כתובת הדואר האלקטרוני או
          שם המשתמש עבור חשבון PayPal מחובר, Google או Facebook), וכל מידע אישי
          אחר שנמסר לנו על ידי המבקרים ו/או המשתמשים באתר ו/או במערכת. בכדי לקבל
          שירותים באתר ביוטיפלוס, עליך לבצע הליך רישום. במסגרת ההרשמה תתבקש
          למסור מידע כדוגמת שמך המלא, שם החברה, תמונתך, מספר ת.ז./ח.פ., כתובת
          דואר אלקטרוני, כתובת פיזית, פרטי כרטיס אשראי ועוד. מבלי שתמלא את
          הנתונים הנדרשים לא תוכל להירשם לשירותים נשוא המערכת. המידע שתמסור בעת
          ההרשמה לשירותים במערכת, יישמר במאגר המידע של ביוטיפלוס. אינך מחויב על
          פי חוק למסור את המידע, אולם ללא מסירתו לא תוכל להשתמש בשירותים אלו
          ובמערכת. באם השארת פרטים ליצירת קשר באתר ביוטיפלוס ללא הרשמות למערכת
          ולשירותים (כמוגדר בסעיף 1.6), ייתכן ופרטים אלה ואחרים ישמשו את
          ביוטיפלוס ליצירת קשר עימך. בעת ההרשמה למערכת, תוכל לקשר את חשבון הרשת
          החברתית שלך (כדוגמת פייסבוק) למערכת ולהירשם באמצעותו. אם תבחר להירשם
          בצורה זו, יועבר למערכת מידע כפי שהנהלת הרשת החברתית תאשר, והינך מאשר
          כי תהיה כפוף להליך האישור במסגרת רשת חברתית זו וכן יחולו עליך ההוראות
          החלות באותה רשת חברתית. בנוסף, הינך מאשר כי הפרטים שמילאת באותה רשת
          חברתית, כדוגמת שמך המלא, תמונת פרופיל, טווח גילאים, מין, ומידע פומבי
          נוסף כדוגמת כתובת דואר אלקטרוני ומספר מזהה, יועברו למערכת בכפוף לאישור
          הרשת החברתית. בנוסף, הינך מסכים ומאשר לביוטיפלוס כי השימוש במערכת,
          במידע, בשירותים ובתכנים הכלולים בה ישמרו במאגרי ביוטיפלוס וישמשו את
          ביוטיפלוס לכל שימוש הנחוץ לתפעול התוכנה. ביוטיפלוס מתחייבת לשמור על
          סודיות המידע, בכפוף להוראות הדין ותנאי השימוש. ביוטיפלוס אוספת מידע
          לגביך ולגבי שימושך ב אתר ו/או המערכת למטרות הבאות: לספק ולהפעיל את
          השירותים המוענקים באמצעות המערכת והאתר (להלן: "השירותים"); כדי להמשיך
          ולפתח, להתאים אישית ולשפר את השירותים, האתר והמערכת; לספק לך סיוע שוטף
          בלקוחות ותמיכה טכנית; כדי ליצור איתך קשר באמצעות הודעות כלליות או
          אישיות הקשורות לשירותים, וכן הודעות קידום מכירות (כמפורט להלן); כדי
          ליצור נתונים סטטיסטיים מצטברים ומידע מצטבר אחר ו/או מוסק שאינו אישי,
          שבו ביוטיפלוס או שותפיה העסקיים עשויים להשתמש כדי לספק ולשפר את
          השירותים ובכפוף לאמור בסעיף 1.8 להלן; כדי לשפר את יכולות אבטחת המידע
          ומניעת הונאות; לציית לכל החוקים והתקנות הרלוונטיים במדינת ישראל.
          השירותים אינם מיועדים או מורשים לקטינים מתחת לגיל 18. ביוטיפלוס אוספת,
          מאחסנת ומעבדת מידע אישי של לקוחותיך (להלן: "הלקוחות") כחלק ממתן
          השירותים והפעלת המערכת. למטרות אלה ביוטיפלוס משמשת ותחשב כ"מחזיק" ולא
          כ"בעלים" של מאגר מידע אלו. המשתמשים שעושים שימוש במערכת לשם הפעלת מאגר
          מידע של לקוחותיהם ("מאגר מידע לקוחות") אחראים לציית לכל החוקים והתקנות
          אשר עשויים לחול על איסוף ושליטה במידע שנאסף על לקוחותיהם, כולל כל חוקי
          הפרטיות והגנה על מאגרי מידע. אתה אחראי לביטחון, לשלמות ולשימוש המורשה
          במידע האישי של הלקוחות שלך, וכן לקבלת הסכמות, הרשאות ולספק הודעות
          עיבוד נאותות הנדרשות לצורך איסוף מידע זה ושימוש בו.
        </p>
        <p>
          2. מסירת מידע לצד שלישי ביוטיפלוס משתפת פעולה עם מספר ספקי שירות
          נבחרים, אשר השירותים והפתרונות שלהם משלימים, מאפשרים ומשפרים את
          השירותים. ספקי שירות אלו כוללים שירותי אירוח (hosting), רשתות תקשורת,
          שירותי אבטחה מקוונת, שירותי עיבוד תשלומים ותשלומים, ניתוחי אינטרנט,
          ספקי תוכן וכדומה. ביוטיפלוס לא תעביר ו/או תציג לצדדים שלישיים את פרטיך
          האישיים והמידע שנאסף על פעילותך כתוצאה מהשימוש במערכת, אלא במקרים
          הבאים: במידה ונתת את הסכמתך המפורשת לכך. בעת שאתה רוכש מוצרים ושירותים
          מביוטיפלוס, יועבר המידע הנדרש לחברת סליקה חיצונית/לצד שלישי לצורך
          השלמת הליך הרכישה. במקרה שתפר את תנאי השימוש באתר ביוטיפלוס, או תבצע
          או תנסה לבצע פעולות הנחזות להיות מנוגדות לדין באתר/במערכת או באמצעותם.
          במידה וניתן צו שיפוטי או הוראה של גורם מוסמך על פי דין המורה
          לביוטיפלוס למסור את פרטיך או המידע לצד שלישי. ביוטיפלוס שומרת לעצמה את
          הזכות לעשות שימוש במידע אודותיך או אודות השימוש שלך באתר ללא קבלת
          רשותך, במקרה בו היא מאמינהבתום לב שהפעולה חיונית לצורך ציות לדרישות
          החוק או מתוך צורך להגן על זכויותיה, במקרה של טענה, תביעה או דרישה
          כלפיה, או במקרה בו יתנהלו הליכים משפטיים בינך לבין ביוטיפלוס.
          ביוטיפלוס תהיה רשאית להעביר את פרטיך והמידע שנאסף בעקבות השימוש שעשית
          באתר ו/או במערכת לחברות קשורות אליה, ובלבד שהן תשמשנה במידע זה רק על
          פי הוראות מדיניות פרטיות זו, ובהתאם למטרותיו הרשומות של מאגר המידע,
          והדין החל על השימוש בו.
        </p>
        <p>
          3. איסוף מידע לצרכים סטטיסטיים ופרסום ביוטיפלוס מבצעת ניתוחים
          סטטיסטיים אודות השימוש באתר ובמערכת. לשם כך, ביוטיפלוס אוספת ומנתחת
          מידע על היקף השימוש באתר ובמערכת, תדירות השימוש בהם, מקורות הגישה שלך
          לאתר וכיוצא בזה. המידע הנאסף הוא סטטיסטי במהותו, הוא איננו מזהה אותך
          אישית והוא נועד לצרכי ניתוח, מחקר ובקרה. באמצעות מידע זה ביוטיפלוס
          תוכל להציע לך, בין היתר, הצעות לייעול ו/או לשימוש אחר במלאי ובסוגי
          מוצרים שלך. לדוגמא אם בהסתמך על איסוף הנתונים הסטטיסטי המערכת תאתר כי
          אתה מוכר מוצרים במחיר נמוך יותר מעסקים דומים לעסק שלך, המערכת עשויה
          לשלוח לך הצעת ייעול. בצורה דומה נשתמש במידע שנלקח ממך בצורה סטטיסטית
          שלא מזהה אותך אישית כדי להציע הצעות דומות למשתמשים אחרים וכן לצדדים
          שלישיים שאינם משתמשים. בנוסף, המערכת עשויה להשתמש במידע זה כדי להציע
          לך לרכוש מוצרים דומים לאלה שאתה רוכש ישירות דרך המערכת וכן לייצר
          התרעות לפני סיום מלאי המוצרים שלך.
        </p>
        <p>
          4. אינדקס לקוחות ביוטיפלוס תהיה רשאית להכניס את שמך לאתר ביוטיפלוס
          לתוך “אינדקס לקוחות” בו מופיעים שמות הלקוחות של ביוטיפלוס (שמות בלבד,
          ללא פרטי התקשרות). ברשותך לבחור שלא להיכנס לאינדקס הלקוחות. על מנת
          לבקש להסיר את שמך מאינדקס הלקוחות אנא צור קשר עם ביוטיפלוס בכתובת
          הדוא”ל support@beautyplus.cloud
        </p>
        <p>
          5. אבטחת מידע ביוטיפלוס מקדישה משאבים ונוקטת אמצעי אבטחה למניעת חדירה
          לאתר ביוטיפלוס ולשרתי המערכת ולסיכול פגיעה אפשרית בפרטיות לקוחותיה.
          פרטי כרטיס האשראי של הלקוחות והנתונים המועלים על ידי הלקוחות למערכת
          נשמרים באופן מוצפן ומאובטח ולפי המחויב לפי החוק והדין למאגר מידע רשום
          ולנתונים המאוחסנים בו. בעוד שאמצעים אלה מצמצמים את הסיכונים לחדירה
          בלתי מורשית, אין הם מעניקים בטחון מוחלט ולכן לא ניתן לשלול שיבושים או
          להבטיח באופן מוחלט את שמירת הפרטיות מפני שימוש בזדון וחדירות מצד
          גורמים שאינם מורשים. ביוטיפלוס לא תהיה אחראית לכל נזק מכל סוג שהוא,
          עקיף או ישיר, שייגרם ללקוח ו/או למי מטעמו אם מידע זה יאבד או אם יעשה
          בו שימוש לא מורשה. הינך מתחייב לשמור בסודיות את שם המשתמש והסיסמא
          הנדרשים לשם חיבור למערכת ולא להעבירם או לגלותם למי שאינו עובד או מורשה
          מטעמך. הקפד לשנות את הסיסמה שלך לעתים קרובות ולפחות אחת לשישה חודשים.
        </p>
        <p>
          6. אחסון מידע אישי שלך, של אלו המבקרים באתר, משתמשי המערכת והמשתמשים
          של המשתמשים נשמר, מאוחסן ומעובד על ידי ביוטיפלוס והשותפים המורשים שלה
          ונותני השירותים שלה, בישראל ובתחומי שיפוט אחרים, לפי הצורך, לצורך
          המסירה הנכונה של השירותים ו/או כפי שיידרש על פי דין.
        </p>
        <p>
          7. Cookies כדי שחלק מטכנולוגיות המערכת יפעלו כראוי, יש להוריד ולאחסן
          קובץ נתונים קטן (“קובץ cookie”) במחשב/מכשיר ממנו מופעלת המערכת. כברירת
          מחדל, ביוטיפלוס משתמשת במספר קובצי cookie קבועים לצורך אימות של פעולות
          באתר ובאימות משתמשים, אבטחה, שמירה על העדפות המשתמש (לדוגמה, לגבי שפה
          והגדרות ברירת מחדל) מעקב אחר הביצועים של השירותים וכדי לספק ולשפר את
          השירותים. אם ברצונך למחוק או לחסום קובצי Cookie, עיין באזור העזרה
          והתמיכה בדפדפן האינטרנט שלך לקבלת הנחיות לאיתור הקובץ או הספרייה
          שמאחסנים קובצי cookie. מחיקת קובצי ה- cookie או השבתת קובצי cookie
          עתידיים או טכנולוגיות מעקב עשויים למנוע גישה לאזורים או לתכונות
          מסוימים של השירותים ושל המערכת ולהשפיע לרעה על חווית המשתמש במערכת.
        </p>
        <p>
          8. הזכות לעיין במידע חוק הגנת הפרטיות, התשמ”א- 1981 (“חוק הגנת
          הפרטיות”), מקנה לכל אדם זכות לעיין במידע המוחזק עליו במאגר מידע. לכן,
          אם עיינת במידע שנוגע לך ומצאת כי המידע אינו נכון, שלם, ברור או מעודכן,
          או שאינך מעוניין שפרטיך יכללו במאגר, אתה רשאי לפנות לביוטיפלוס ולבקש
          מאיתנו לתקן את המידע או למחקו, ללא תשלום כלשהו, בהתאם להוראות חוק הגנת
          הפרטיות והתקנות מכוחו. אולם, שים לב כי המידע דרוש לשם רישום באתר
          ובמערכת ומחיקתו עלולה למנוע ממך להמשיך ולקבל שירותים מהמערכת ולהביא
          לביטול המנוי שלך. מידע הדרוש לביוטיפלוס לרבות תיעוד פעולות שביצעת באתר
          – יוסיף להישמר על ידי ביוטיפלוס על פי דין, אך לא ישמש עוד לצורך פניות
          אליך.
        </p>
        <p>
          9. עמידה בדינים וחוק הגנת הפרטיות ביוטיפלוס מספקת לך פלטפורמה, מערכת
          לניהול העסק אליה אתה מעלה מידע אישי של לקוחותיך עבור השימושים השונים
          שקיימים במערכת. יובהר כי באחריותך הבלעדית לוודא כי השימוש במערכת על
          ידך מתבצע בהתאם לחוק הגנת הפרטיות, תקנות הגנת הפרטיות (אבטחת מידע),
          תשע”ז-2017 ולכל חוק או דין אחר שחלים עליך, לרבות הדינים הנוגעים לרישום
          מאגר המידע בפנקס מאגר המידע, נהלי אבטחת מידע, דיוור ישיר, פניה לנשוא
          המידע לשם קבלת המידע וכדומה. הנך אחראי באופן בלעדי על: קבלת הסכמה
          מפורשת מלקוחותיך לפני הוספתם למאגר מידע שלך. לא לעשות כל שימוש אשר
          עלול לכלול התנהגות האסורה על פי הדין בכל תוכן המועבר מטעמך באמצעות
          המערכת. המערכת מאפשרת יצירת קידומים, בין היתר, באמצעות סמס, מייל
          ורשתות חברתיות. מובהר כי השימוש בשירות זה נעשה על אחריותך, וכי עליך
          בלבד לעמוד בכל הוראות הדין הרלבנטיות בקשר לכך, כולל בין השאר לעניין
          דיני הגנת הפרטיות. למען הסר ספק, בין היתר באחריותך לקבל את הסכמתם
          המפורשת של הגורמים אשר יכללו תחת מאגר זה ולאפשר להם לעדכן את פרטיהם
          ואף להסיר אותם. המערכת מאפשרת, בעת רכישת חבילות שירות מסוימות, הפצת
          מסרונים להפצת מידע ללקוחותיך (להלן: “הודעות סמס”), בכמות אשר תיקבע על
          ידי ביוטיפלוס. הנך מאשר כי אתה מבין כי: תוכן הודעות סמס ו/או עצם
          העברתן יהא באחריותך הבלעדית וכי ביוטיפלוס אינה אחראית לתוכן המופץ על
          ידך ו/או לעצם הפצתן של אותן הודעות סמס. כמו כן, הנך מתחייב לעמוד בכל
          דרישות הדין בקשר להעברת הודעות סמס בעלי תוכן פרסומי ו/או לגבי דיני
          הגנת הפרטיות החלים בעניין זה ובכלל זה לעמוד בסעיף ‏10 להלן. מידע אישי
          של לקוחות מועבר לחברות/ ספקים חיצוניים (כגון רשתות תקשורת) על מנת לספק
          חלק מהשירותים (כגון שירותי שליחת מסרוני סמס ועוד).
        </p>

        <p>
          10. הוספת לקוחות למאגר המידע שלך באפשרותך לצרף את לקוחותיך למאגר המידע
          שלך באמצעות שתי אפשרויות: לאחר בקשת הלקוח שלך את הצטרפותו למערכת, תוכל
          לשלוח לו מסרון סמס באמצעות המערכת, שם יופיע “טופס חיבור”. בטופס החיבור
          כדי לאשר את הצטרפותו למאגר הלקוחות שלך, על הלקוח שלך לאשר קבלת מסרוני
          סמס מהמערכת. באפשרותך, לאחר קבלת אישור מהלקוח, לצרף את הלקוח בצורה
          ידנית למאגר הלקוחות שלך במערכת ולסמן בטופס צירוף זה כי הלקוח אישר קבלת
          מסרוני סמס מהמערכת. יובהר, כי עליך להביא לידי הלקוח את אפשרות שליחת
          מסרוני סמס על ידי המערכת ולאשר זאת רק לאחר קבלת הסכמה מפורשת.
        </p>
        <p>
          11. שינויים במדיניות הפרטיות ביוטיפלוס רשאית להפסיק באופן מיידי את
          השירותים הניתנים לך, ביוטיפלוס עשויה לעדכן מדיניות פרטיות זו כנדרש על
          פי החוק החל ולשקף שינויים באיסוף המידע, השימוש ואחסון המידע על ידה. אם
          יבוצעו במדיניות שינויים מהותיים תפורסם על כך הודעה בעמוד הבית של האתר.
          המשך השימוש באתר ובמערכת לאחר השינוי במדיניות מעיד על הסכמתך למדיניות
          המתוקנת. ביוטיפלוס מעודדת את משתמשיה ומבקריה לעיין בדף זה מעת לעת
          לקבלת המידע העדכני ביותר על נוהלי הפרטיות שלה.
        </p>
        <p>
          12. יצירת קשר אם יש לך שאלות כלשהן בנוגע למדיניות פרטיות זו או שברצונך
          לממש זכויות כלשהן כפי שמתואר לעיל, צור קשר עם צוות הגנת הפרטיות בכתובת
          הדוא”ל support@beautyplus.cloud או בדואר . ביוטיפלוס תנסה לפתור כל
          תלונה בנוגע לשימוש במידע האישי שלך בהתאם למדיניות פרטיות זו.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
