import React from "react";
import { Fade } from "react-reveal";
import bannerBG from "../../../assets/images/banner/graph.png";

const Banner = () => {
  return (
    <>
      <section className="sc-banner-section-area bottom-skew-bg sc-pt-200 sc-sm-pt-150 sc-pb-50 sc-sm-pb-70">
        <Fade top>
          <div className="banner-circle-shape"></div>
        </Fade>
        <div className="container-fluid p-0">
          <div className="row sc-banner-item d-flex align-items-center">
            <div className="col-md-6">
              <div className="banner-image text-end shake-y ml-20">
                <img className="banner-img" src={bannerBG} alt="Banner" />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="banner-header-content"
                style={{ position: "relative" }}
              >
                <Fade left>
                  <h1 className="banner-title p-z-idex white-color sc-mb-25 sc-sm-mb-15">
                    גורמת לעסק שלך להרוויח יותר
                  </h1>
                  <div className="banner-sub-title white-dark-color sc-mb-40 sc-sm-mb-25">
                    התרכזו בלקוחות שלכם ותנו לנו לעסוק בניהול שוטף. נרוויח לכם
                    זמן יקר ונייצר לכם הכנסה נוספת, הגיע הזמן!
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="border-shape"></div>
      </section>
    </>
  );
};

export default Banner;
