import AboutUs from "../../Shared/AboutUs";
import Banner from "./Banner";
import Counter from "./Counter";
import Feature from "../../Shared/Feature";
import Footer from "../../Shared/Footer";
import Header from "../../Shared/Header";
import Home3Apps from "../../Pages/HomeThree/Home3Apps";
import PricePlan from "../../Shared/PricePlan";
import React from "react";
import Service from "../../Shared/Service";
import Testimonial from "./Testimonial";

const HomeOne = () => {
  return (
    <div className="flex row-reverse">
      <Header parentMenu="home" headerClass="sc-header-section" />
      <Banner />
      <Service />
      <AboutUs />
      <Feature />
      {/* <Apps /> */}
      <Home3Apps />
      <Counter />
      <Testimonial />
      <PricePlan />
      <Footer />
    </div>
  );
};

export default HomeOne;
