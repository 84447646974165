import React from "react";

const TermOfUse = () => {
  return (
    <>
      <div dir="rtl">
        <h1 style={{ color: "dodgerblue", fontSize: "40px" }}>
          תנאי השימוש באתר ובתוכנה
        </h1>
        <p>
          1.כללי חברת ביוטיפלוס ו/או חברות קשורות , מציעה את שימוש ומאפשרת לבצע
          פעולות באתר ו/או בתוכנה ו/או באפליקציית החברה (להלן: “הפלטפורמה“).
          פעולה הנה כל גלישה ו/או שימוש בפלטפורמה ובשירותים המוצעים בה, ביצוע
          פעולת הזמנה או רכישת המוצרים המוצעים בפלטפורמה, ככל שמוצעים, וקבלת
          רישיון שימוש בפלטפורמה המוענק למשתמש בהתאם לטפסי ההזמנה פרטניים שיחתמו
          עם מי מהמשתמשים (להלן: “פעולה“).
        </p>
        <p>
          2.תנאי השימוש כתובים בלשון זכר רק מטעמי נוחות ויש לראות בכתוב כמתייחס
          גם ללשון נקבה.
        </p>
        <p>
          3. הוראות תנאי שימוש אלו (להלן: “תנאי השימוש“) ומדיניות הפרטיות של
          החברה יחולו על כל מבצע פעולה בפלטפורמה (לעיל ולהלן: “משתמש“), ויהוו את
          הבסיס המשפטי בכל עניין שבין המשתמש לבין החברה. עקב כך, הנך מתבקש לקרוא
          את תנאי השימוש ומדיניות הפרטיות במלואם ובעיון. ביצוע כל פעולה
          בפלטפורמה, מהווה את הסכמתך לקבל ולנהוג לפי תנאי השימוש ומדיניות
          הפרטיות (כהגדרתה להלן), לכן אם אינך מסכים לתנאי מתנאיהם הנך מתבקש לא
          לעשות כל פעולה בפלטפורמה.
        </p>
        <p>
          4.כל משתמש המבצע פעולה בפלטפורמה מצהיר כי הנו מודע לתנאי השימוש
          בפלטפורמה ומקבלם, וכי לא תהא לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד
          בעלי הפלטפורמה ו/או מפעיליה ו/או מי מטעמם מלבד טענות הקשורות בהפרת
          התחייבויות בעלי הפלטפורמה ו/או מפעיליה על פי תנאי השימוש.
        </p>
        <p>
          5.בעלי הפלטפורמה שומרים לעצמם את הזכות לשנות את תנאי השימוש מפעם לפעם
          על פי שיקול דעתם הבלעדי וזאת ללא צורך במתן התראה ו/או הודעה מוקדמת.
          תנאי השימוש יכנסו לתוקף עם פרסומם באתר.
        </p>
        <p>
          6.המשתמשים בפלטפורמה מסכימים כי רשומות המחשב של בעלי הפלטפורמה בדבר
          הפעולות המתבצעות דרך הפלטפורמה יהוו ראיה לכאורה לנכונות הפעולות.
        </p>
        <p>
          7.בעלי הפלטפורמה עושים ככל יכולתם לוודא שהמידע המוצג בפלטפורמה יהיה
          המידע השלם והמדויק ביותר אך יובהר, כי עלולים להופיע בהם, בתום לב, אי
          דיוקים או שגיאות אשר בעלי הפלטפורמה לא יישאו באחריות כלשהי הנובעת מהן
          או קשורה אליהן.
        </p>
        <p>
          8.בעלי הפלטפורמה רשאים לעדכן את מחירי השירותים ו/או המוצרים באתר ו/או
          את התוכנה עצמה ו/או נגזרות של התוכנה ו/או את הגרסאות של התכנה, מעת לעת
          ובלא צורך בהודעה מוקדמת. המחיר התקף ביחס לשירותים ו/או המוצרים שבוצעו
          ו/או הוסכמו בטפסי ההזמנה השונים הוא המחיר שהתפרסם בעת שהושלם תהליך
          ההזמנה ו/או אישור טפסי ההזמנה (הכולל את מסירת פרטי כרטיס אשראי).
        </p>
        <p>
          9.בעלי הפלטפורמה רשאים להציע למשתמשים הרוכשים רישיון שימוש בתוכנה
          כמפורט בתנאי השימוש, מבצעים, הטבות והנחות, פרטניים לכל משתמש ו/או
          כלליים. בעלי הפלטפורמה רשאים בכל עת להפסיק מבצעים, הטבות והנחות אלה,
          להחליפם או לשנותם, בלא צורך לתת כל הודעה מוקדמת על כך, אלא אם הוסכם
          אחרת בין הצדדים, בכתב.
        </p>
        <p>
          10.תנאי השימוש חלים על השימוש בפלטפורמה באמצעות כל מחשב או מכשיר
          תקשורת אחר (כדוגמת טלפון סלולארי, מחשבי כף יד למיניהם וכיו”ב). כמו כן,
          תנאי השימוש חלים על השימוש בפלטפורמה בין אם באמצעות רשת האינטרנט, ובין
          אם באמצעות כל רשת או אמצעי תקשורת אחרים.
        </p>
        <p>
          11.התוכנה תוכנת כשירות (SaaS) לניהול עסקים קטנים (לעיל ולהלן:
          “התכנה“). התוכנה, תוצריה והמידע המופק ו/או הנשמר במסגרת השימוש בה,
          מאוחסנים אצל ספק חיצוני המעניק לחברה שירותי ענן. בעניין זה ראה סעיף 7
          פרטיות ושימוש במידע.
        </p>
        <p>
          12.קניין רוחני וזכויות יוצרים המשתמש מאשר כי התוכנה כהגדרתה להלן,
          המסמכים הנלווים לה ו/או כל חומר אשר יועבר למשתמש על ידי החברה בעניין
          וכל זכויות הקניין הרוחני הנלוות והקשורות להם, לרבות המצאות, פטנטים
          ובקשות לפטנטים, סימני מסחר, שמות מסחר, לוגו, חומרים המוגנים בזכויות
          יוצרים, גרפיקות, טקסטים, תמונות, עיצובים, מפרטים, שיטות, תהליכים,
          אלגוריתמים, נתונים, מידע טכני, מאפיינים אינטראקטיביים, קוד מקור
          ואובייקטים, קבצים, ממשקים גרפיים וסודות מסחר, בין אם רשומים או ניתנים
          לרישום ובין אם לאו (ביחד להלן: “הקניין הרוחני“) שייכים לחברה והמשתמש
          לא יהיה רשאי לעשות בקניין הרוחני כל שימוש שלא פורט מפורשות בתנאי
          השימוש. המשתמש לא יהיה רשאי להעתיק, להפיץ, להציג, לבצע בפומבי, להעמיד
          לרשות הציבור, לפרק, להפריד, להתאים, לתת רישיון משנה, לעשות שימוש
          מסחרי, למכור, להשכיר, להשאיל, לעבד, לחבר, לבצע “הנדסה הפוכה”, לשלב עם
          תוכנות אחרות, לתרגם, לשנות או ליצור יצירות נגזרות של הקניין הרוחני,
          בעצמו או על-ידי מי מטעמו בכל אופן שהוא.
        </p>
        <p>
          13.המשתמש מאשר כי כל העלאת תכנים ו/או תמונות ו/או נתונים ו/או כל מידע
          אחר (להלן: “המידע המוזן“) לצורך השימוש בתוכנה, אינו מוגן בזכויות
          יוצרים והחברה יכולה לעשות שימוש במידע המוזן לצורך אספקת השירותים ו/או
          לצרכי פרסום ושיווק ולא תהיה למשתמש כל טענה בדבר זכויות יוצרים ו/או
          סימן מסחר כלפי החברה ו/או כל טענה אחרת כלפי החברה באשר לשימוש במידע
          המוזן בהתאם לתנאי השימוש וההזמנה, ככול שזו תחתם בין המשתמש לחברה.
        </p>
        <p>
          14.המשתמש מאשר כי ידוע לו שהפרה של האמור בסעיף 3 זה יגרמו לחברה נזק
          בלתי ניתן לשיעור או לפיצוי ועל כן תהא החברה רשאית, מבלי לגרוע מכל סעד
          אחר על פי תנאי השימוש ו/או ההסכם או על פי דין, להוציא כנגד המשתמש צווי
          מניעה ו/או צווים לביצוע בעין.
        </p>
        <p>
          15.המשתמש מתחייב שלא להעלות כל טענה ו/או דרישה, בין באופן ישיר ובין
          באופן עקיף, לעניין זכות ו/או כל אינטרס אחר אשר יש לחברה ביחס לתוכנה
          ו/או למסמכים הנלווים להם ו/או ביחס לכל הזכויות הקניין הרוחני הנלוות
          ו/או הקשורות להם. המשתמש מתחייב בנוסף, שלא לרשום, להגיש לרישום ו/או
          לנסות לקבל כל הגנה משפטית ביחס לתוכנה ו/או למסמכים הנלווים ו/או ביחס
          לכל הזכויות הקניין הרוחני הנלוות ו/או הקשורות להם, בין באופן ישיר ובין
          באופן עקיף.
        </p>
        <p>
          16. על אף האמור בתנאי השימוש, ההתחייבויות המפורטות תחת סעיף 3 זה
          ימשיכו לחול גם לאחר סיומו מכל סיבה שהיא.
        </p>
        <p>
          17.רישיון שימוש בתוכנה בכפוף לקיום כל הוראות והתחייבויות המשתמש על פי
          תנאי השימוש ועפ”י טופס ההזמנה, מוענקת למשתמש, במהלך תקופת ההסכם
          (כהגדרתה להלן), רישיון שימוש ייחודי, מוגבל ושאינו בלעדי בתוכנה (להלן:
          “רישיון השימוש“)
        </p>
        <p>
          18.מובהר כי המשתמש לא יוכל להעביר ו/או להשכיר את רישיון השימוש ו/או את
          זכויותיו ו/או חובותיו על פי תנאי השימוש ו/או ההסכם, לכל צד שלישי ללא
          אישורה בכתב של החברה.
        </p>
        <p>
          19.היקף השימוש המשתמש מתחייב, כי יעשה שימוש בתוכנה אך ורק בהתאם ובכפוף
          להוראות תנאי השימוש זה והוראות טופס ההזמנה.
        </p>
        <p>
          20.עם התקנת התוכנה, יוקצה למשתמש שם משתמש וסיסמא לצורך הרשאת גישה
          לשימוש בתכנה (להלן: “פרטי המשתמש“). המשתמש מתחייב לשמור את פרטי המשתמש
          בסודיות ולא להעבירם לכל צד שלישי ולהודיע לחברה באופן מידי על כל מקרה
          של אבדן ו/או של חשש לגישה בלתי מורשית באמצעות פרטי המשתמש לתכנה.
          יובהר, כי החברה לא תישא בכל אחריות בגין כל אבדן ו/או גישה בלתי מורשית
          כאמור.
        </p>
        <p>
          21.המשתמש מתחייב שהנתונים המופקים במסגרת התוכנה ישמשו אך ורק את המשתמש
          ואת עסקיו למטרת קבלת החלטות ו/או ביצוע פעולות על ידו. המשתמש מתחייב
          שלא להעביר, בכל אופן ו/או צורה שהיא, כל מידע המופק מהתוכנה, לכל גורם
          שלישי, זאת למעט כפי המחויב על פי דין. יובהר כי הפרה של סעיף זה מהווה
          הפרה יסודית של ההסכם.
        </p>
        <p>
          22.החברה תהיה רשאית, בכפוף למתן הודעה סבירה מראש למשתמש, לבצע בדיקות
          של מערכות המשתמש לצורך בחינה, כי השימוש, אשר נעשה על ידי המשתמש בתוכנה
          נעשה בהתאם להוראות התנאי השימוש ו/או טופס ההזמנה וכן לצורך בחינת קיום
          התחייבויותיו של המשתמש ע”פ התנאי השימוש ו/או טופס ההזמנה. המשתמש
          מתחייב לשתף פעולה עם החברה בביצוע ביקורות כאמור ומתחייב לספק לחברה
          גישה סבירה למערכותיו ו/או למסמכים רלוונטיים, אשר יידרשו ע”י החברה
          לעניין זה. החברה מתחייבת לעשות כמיטב יכולתה שלא להפריע לעסקו של המשתמש
          במהלך ביצוע ביקורות כאמור.
        </p>
        <p>
          23.התקנה עם החתימה על טופס ההזמנה יתואם עם המשתמש מועד התקנה של התכנה
          ו/או הגדרת שם המשתמש והסיסמא לגישה לתוכנה. הגדרה ו/או התקנה כאמור
          תתבצע באמצעות מי מטעם החברה בלבד. החברה לא תישא בכל אחריות לכל נזק
          בגין התקנה של המשתמש ו/או מי מטעמו ו/או כל צד שלישי שאינה בהתאם לאמור
          בסעיף זה.
        </p>
        <p>
          24.מבלי לגרוע מהאמור לעיל, המשתמש מתחייב שלא לבצע כל שינויים במערכת
          ו/או ינסה לתקנה בכל צורה ו/או אופן שלא בהתאם להוראותיה ואישורה המפורש
          של החברה ומאשר בזאת כי ידוע לו כי הוא נדרש לדווח וליידע את החברה בדבר
          כל תקלה ו/או בעיה כאמור באופן מיידי.
        </p>
        <p>
          25.באחריות המשמש להתקין על גבי המחשב בו תותקן התוכנה מכשיר אל פסק,
          והמשתמש מוותר בזאת על כל טענה ו/או תביעה כנגד החברה לכל אבדן של נתונים
          ו/או נזק אשר עלולים לקרות בשל אי התקנת מכשיר אל פסק כאמור לעיל.
        </p>
        <p>
          26.פרטיות ושימוש במידע מדיניות הפרטיות של החברה, עוסקת בין היתר,
          בשמירת המידע, השימוש במידע ואופן האיסוף של המידע (להלן: “מדיניות
          הפרטיות“), בקישור הבא: https://beautyplus.cloud/PrivacyPolicy הפרה של
          האמור בסעיף 7 זה מהווה הפרה יסודית של ההסכם.
        </p>
        <p>
          27.אחריות השימוש בתוכנה מתאפשר למשתמש על בסיס “כמות שהוא” (AS IS).
          החברה מתחייבת שבמשך תקופת ההתקשרות בין הצדדים החברה תעשה את מירב
          המאמצים הטכנולוגיים והמסחריים כדי שהתוכנה תפעל באופן סדיר, וכי במהלך
          תקופת התקשרות בין הצדדים החברה תפעל בהתאם לשיקול דעתה הבלעדי של החברה,
          להפעלת אמצעים סבירים על ידי החברה על מנת לספק למשתמש פתרון לתקלה ו/או
          לבעיה המונעת תפקוד תקין של התוכנה (להלן: “תיקון“) ובלבד שהמשתמש הודיע
          לחברה על תקלה ו/או בעיה כאמור.
        </p>
        <p>
          28.מבלי לגרוע מכלליות האמור בסעיף 10.1 המשתמש מצהיר ומסכים כי בכל מקרה
          לא תקום לחברה אחריות כלשהי במקום בו התקלה ו/או הבעיה בתוכנה נגרמה
          כתוצאה: (א) משימוש של המשתמש בתוכנה שלא בהתאם להוראות התנאי השימוש
          ו/או טופס ההזמנה; (ב) משינויים כל שהם שנעשו לתוכנה ושלא אושרו על ידי
          החברה; (ג) מכשל בחומרה בה עושה שימוש המשתמש לצורך הפעלת התוכנה; (ד)
          כשל או פגם בתוכנה; (ה) כשל, פגם או אי התאמה עם תוכנה אחרת המותקנת או
          פועלת על המחשב עליו הותקנה התכנה ו/או אשר ממנו ניתנה הגישה לשימוש
          בתוכנה; (ו) מכל סיבה אחרת שאינה בשליטתה של החברה. עוד יובהר, כי המשתמש
          לא יזכה באחריות כאמור מקום בו לא עמד המשתמש בחובותיו הכספיות כלפי
          החברה על פי ההסכם.
        </p>
        <p>
          29.החברה אינה מתחייבת כי התוכנה תפעל ללא הפרעות או ללא תקלות, כי
          הפונקציות הנכללות בתוכנה יפעלו ויתאמו לתוכנות, חומרות אחרות או שהתוכנה
          תענה לצרכיו הספציפיים של המשתמש.
        </p>
        <p>
          30.החברה לא תהא אחראית לכל שימוש שיעשה המשתמש בתוצאות ו/או בכל מידע
          אחר אשר יופק במסגרת השימוש בתוכנה, ובכלל זה על כל החלטה עסקית שיעשה
          המשתמש על בסיס תוצאות השימוש בתוכנה, לרבות בקשר עם הדיווחים לרשויות
          המיסים. מבלי לגרוע מהאמור, יובהר כי התוכנה אמנם אושרה ע”י מס הכנסה
          ונרשמה באתר מס הכנסה אך היא עומדת לשימוש המשתמש “כמות שהוא” (AS IS)
          ומהווה אך ורק כלי עזר לפעילותו של המשתמש וכל שימוש אשר נעשה בתוכנה על
          ידי המשתמש הינו על אחריותו הבלעדית. המערכת איננה מערכת הנהלת חשבונות
          ואיננה באה להחליף מערכת הנהלת חשבונות, כפי המחויב, אם מחויב, המשתמש,
          על פי דין.
        </p>
        <p>
          31.למעט כמפורט באופן ספציפי בהסכם, החברה לא תישא בכל אחריות לכל נזק
          ו/או הפסד ו/או אבדן רווחים, אשר ייגרם ו/או עלול להיגרם למשתמש ו/או למי
          מטעמו כתוצאה משימוש בתוכנה, אי שימוש בה, זמינות מוגבלת ו/או מידע חלקי
          או חסר. החברה לא תישא, בכל מקרה, בכל אחריות לנזק עקיף, משני, תוצאתי
          ו/או הפסדים אשר נובעים ו/או אשר קשורים בכל צורה ו/או אופן להתקשרות בין
          הצדדים ו/או לתנאי השימוש או לשימוש בפלטפורמה אף אם החברה ידעה בדבר
          האפשרות לקרות נזק כאמור. מבלי לגרוע מכל האמור בהסכם, יובהר כי בכל מקרה
          סך האחריות המצטברת של החברה בגין כל הפרה של הסכם ההתקשרות בין הצדדים
          ו/או התנאי השימוש תהא מוגבלת לדמי השימוש החודשיים, אשר שולמו בפועל
          לחברה, בגין השימוש בתוכנה במהלך 12 החודשים שקדמו למועד הנזק הנטען על
          ידי המשתמש.
        </p>
        <p>
          32.החברה אינה נושא באחריות ולא תשלם כל פיצוי ו/או שיפוי עבור נזק מכל
          סוג שהוא עבור הטיפול אותו מעניק המשתמש ללקוחותיו ו/או עבור טיפול אשר
          קיבל המשתמש ו/או עבור מוצרים וחומרים בהם עשה שימוש המשתמש, אף אם נעשה
          שימוש בפלטפורמה לצורך רכישת החומרים ו/או המוצרים ו/או הזמנת השירות.
          החברה אינה נושא באחריות לאופי הטיפול, לטיב הטיפול ו/או החומרים ו/או
          המוצרים, למקצועיות הטיפול ולבטיחות הטיפול ו/או החומרים ו/או המוצרים
          וכל נזק שיגרם חל על המשתמש עצמו ו/או ספק המוצרים, לפי העניין.
        </p>
        <p>
          33.החברה גובה את הכספים באמצעות כרטיסי אשראי ובהתאם לפרטים ונתונים אשר
          מספקים המשתמשים. החברה לא תישא באחריות ו/או בנזק ו/או פיצוי מכל סוג
          שהוא ככול והמשתמש העביר לחברה פרטי חיוב לא נכונים ו/או לא מדויקים ו/או
          לא מלאים אשר בעקבות זאת נגרם נזק למשתמש ו/או ללקוח המשתמש, ככול והחברה
          תפעל על פי טופס ההזמנה ו/או טופס החיוב אשר המשתמש מילא.
        </p>
        <p>
          34.פיצוי ושיפוי החברה מתחייבת לשפות את המשתמש בכל מקרה של תביעה אשר
          תוגש נגדו על ידי צד שלישי בגין טענה כי השימוש בתוכנה לפי ההסכם מהווה
          הפרה של זכויות יוצרים ותישא בנזקים ו/או בעלויות הכרוכות ביישוב ו/או
          סילוק התביעה ו/או אלו אשר ייפסקו כנגד המשתמש במסגרת פסק דין חלוט והכל
          בלבד שהמשתמש (א) הודיע לחברה בהקדם האפשרי אודות תביעה כאמור והעניק
          לחברה את כל מידע, סיוע וסמכויות אשר יידרשו על ידי החברה מאת המשתמש
          לצורך ניהול הגנה מפני תביעה כאמור ובכלל זה הסמכות לנהל מו”מ ולהגיע
          להסדר פשרה ביחס לתביעה כאמור.
        </p>
        <p>
          35.החברה לא תישא בכל אחריות במסגרת ההסכם, ובכלל זה בכל אחריות לפי סעיף
          10.1 לעיל, ביחס לכל תביעה אשר עילתה (א) שינוי של התוכנה על ידי כל גורם
          שאינו החברה; (ב) שימוש בכל גרסה שאינה הגרסה האחרונה של התוכנה; (ג)
          שימוש בתוכנה בשילוב עם מידע ו/או נתונים של המשתמש ככל ששימוש כאמור
          הוליד את עילת התביעה; (ד) שימוש בתכנה בשילוב עם תוכנות ו/או חומרות
          נוספות ככל ששימוש כאמור הוליד את עילת התביעה; (ה) שימוש בתוכנות
          קוד-פתוח (open source) בשילוב עם התכנה; (ו) כל שימוש בתכנה בניגוד
          להוראות ההסכם; (ז) הסתמכות המשתמש על תוצאות השאילתות שביצע ו/או הפיק
          מהתוכנה לצורך כל פעולה עסקית שביצע.
        </p>
        <p>
          36.ככל שיוחלט על ידי החברה כי השימוש בתוכנה עלול להקים טענה של פגיעה
          בקניין רוחני ו/או הפרה של זכויות יוצרים, תהא להחברה, על פי שיקול דעתה
          הבלעדי, הזכות (א) להחליף את התכנה בתכנה אחרת אשר תענה על הדרישות
          הפונקציונאליות של המערכת; (ב) לשנות את התכנה באופן שתהא נקיה מכל הפרה
          כאמור; (ג) לרכוש, ללא עלות נוספת למשתמש, זכות להמשיך להשתמש בתכנה; (ד)
          להפסיק את רישיון השימוש ולהשיב למשתמש את התמורה אשר שולמה על ידו בגין
          השימוש בתכנה.
        </p>
        <p>
          37.התנאים המפורטים בסעיף 10 זה מהווים את גבול האחריות של החברה והסעדים
          היחידים להם יהיה זכאי המשתמש בכל מקרה של תביעה שעניינה הפרה ו/או פגיעה
          של זכויות יוצרים, קניין רוחני ו/או סוד מסחרי ובאים במקום כל סעד,
          אחריות או מחויבות אחר של החברה לפי ההסכם ו/או בכלל.
        </p>
        <p>
          38.המשתמש מתחייב לשפות את החברה, בגין כל נזק ו/או הוצאה (לרבות שכ”ט
          עו”ד) בכל מקרה בו צד ג’ כלשהו, יתבע את החברה, בגין שימוש שעשה המשתמש
          בפלטפורמה ו/או לקוח המשתמש ו/או שימוש שעשתה החברה במידע המוזן כאמור
          בסעיף 3.2 לתנאי השימוש, לרבות משלוח הודעות באמצעות המערכת לצד ג’, ואשר
          בעטיו של השימוש ו/או המעשה האמור צד ג’ בחר לתבוע את החברה.
        </p>
        <p>
          39.שירותי תמיכה, תשתיות וחומרה במהלך תקופת הרישיון, מתחייבת החברה
          להעמיד לטובת המשתמש את מכלול השירותים המפורטים להלן (להלן: “שירותי
          התמיכה“). מתן הדרכה ראשונה למשתמש החברה על השימוש והתפעול של התוכנה.
          אספקת שירותי תחזוקה למשתמש, תמיכה וסיוע (helpdesk) לצורך ההפעלה
          והשימוש השוטף בתוכנה ולצורך אבחון, טיפול ותיקון תקלות ו/או ליקויים
          ו/או טעויות תפעוליות בתוכנה (להלן: “שגיאה“), אם יתגלו כאלו במהלך תקופת
          ההסכם. תיקון שגיאות בתוכנה, אם וככל שתהיינה, וזאת על בסיס מאמץ סביר
          ובהתאם להוראות ההסכם. העמדה של איש מטעם החברה, לרשות המשתמש אשר יהיה
          זמין טלפונית בין השעות 09:00 – 18:00 בימים א’ – ה’ ובימי ו’ וערבי חג
          בין השעות 09:00 – 13:00 ו/או בדוא”ל support@beautyplus.cloud בכל שעות
          היממה (החברה תשיב לדוא”ל בדוא”ל חוזר ו/או בפנייה טלפונית, זאת בתוך 48
          שעות ממועד קבלת הדוא”ל), לצורך אספקת שירותי התמיכה, באתר התקנת התוכנה
          או על ידי שירות מרחוק, לפי החלטת ושיקול דעת החברה.
        </p>
        <p>
          40.תקופת ההסכם החברה תעניק את רישיון השימוש ושירותי התמיכה מכוחו בהתאם
          לתנאי השימוש, מדיניות הפרטיות ולהזמנה שתחתם בין החברה לבין המשתמש
          (להלן: “ההסכם“), ההסכם יהיה בתוקף מיום החתימה על ידי המשתמש והחברה וכל
          עוד לא בוטל בהתאם להוראות תנאי השימוש (להלן: “תקופת ההסכם“). ביטול
          ההסכם על ידי החברה יהיה בהתאם להוראות תנאי הביטול בהסכם. אולם, בכל אחד
          מן המקרים המפורטים להלן, תהיה החברה רשאית, לסיים את ההסכם באופן מידי,
          על ידי משלוח הודעה בכתב למשתמש: אם ימונה כונס נכסים ו/או מנהל ו/או
          מפרק, והכל בין זמניים ובין קבועים, למשתמש ו/או לעסקיו ו/או לרכושו,
          כולם או חלקם, או אם יוצא כנגדו צו הקפאת הליכים ו/או אם היא יזמן אסיפת
          נושים לשם מציאת הסדר עמהם, או יגיע להסדר עמהם; אם יוטל עיקול על עיקר
          נכסיו או חלק עיקרי מנכסיו של המשתמש ועיקול זה לא יוסר תוך 15 ימים;
          הוגשה בקשת פירוק כנגד המשתמש או שהמשתמש קיבל החלטה על פירוקו מרצון;
          הפרה של ההסכם על ידי המשתמש אשר לא תוקנה בתוך 7 ימים ממשלוח הודעה בכתב
          מאת החברה; הפרה יסודית של ההסכם על ידי המשתמש. מובהר, כי בכל מקרה של
          איחור בביצוע תשלומים בהם מחויב המשתמש על פי ההסכם תהא החברה רשאית
          להשעות את גישת המשתמש למערכת באופן מידי, לאחר מתן התראה טלפונית או
          בכתב בת 24 שעות. המשתמש יהא רשאי להביא את ההסכם לסיומו בכפוף להודעה
          מוקדמת ובכתב של 30 ימים לכל הפחות אשר תישלח לחברה באמצעות מייל לכתובת
          support@beautyplus.cloud .
        </p>
        <p>
          41.שונות תנאי השימוש ו/או ההסכם ממצים את כל המוסכם בין הצדדים והם
          מחליפים ומבטלים כל הבנה, התחייבות, הסכם ו/או זיכרון דברים קודם שנעשה
          בין הצדדים לו, אם נעשה, בין בעל פה ובין בכתב, בין במפורש ובין מכללא.
          שום התנהגות מצד מי מן הצדדים לא תיחשב כויתור על איזה מזכויותיו על פי
          תנאי השימוש ו/או ההסכם או על פי כל דין, או כויתור או כהסכמה מצידו
          לאיזו הפרה או אי קיום תנאי כלשהו או התחייבויות כלשהיא, אלא אם הויתור,
          ההסכמה, הדחייה, השינוי, הביטול או התוספת נעשו במפורש ובכתב. כל שינוי
          או תוספת לתנאי השימוש ו/או ההסכם, יכול שייעשה ויהיה לו תוקף, רק אם
          ייערך בכתב ונחתם על ידי שני הצדדים. היה ויקבע על ידי רשות שיפוטית ו/או
          אחרת כי הוראה מהוראות תנאי השימוש ו/או ההסכם חורגת מגבולות החוק ו/או
          אינה חוקית, יבצעו הצדדים התאמה של הוראות תנאי השימוש ו/או ההסכם, לפי
          העניין, באופן בו תתוקן ההוראה בנוסח ההולם את החוק בצורה המרבית. מבלי
          לגרוע מכלליות האמור, במקרה בו ייקבע כי הוראה מהוראות תנאי השימוש ו/או
          ההסכם הינה בטלה, תבוצע הפרדה של יתר הוראות תנאי השימוש ו/או ההסכם, לפי
          העניין, לחלקים, ורק החלק אליו נוגעת העילה האמורה יבוטל, כאשר יתר
          הסעיפים יוותרו ללא שינוי. המשתמש אינו רשאית למסור, להסב, להמחות או
          להעביר לאחר(ים) זכויותיו ו/או חובותיו על לפי תנאי השימוש ו/או ההסכם
          ו/או חלקן, שלא בהסכמת החברה מראש ובכתב ולא יהיה כל תוקף להסבה, העברה,
          המחאה שנעשו שלא על פי סעיף זה. הפרת סעיף זה מהווה הפרה יסודית של
          ההסכם. החברה רשאית להסב, להמחות או להעביר את זכויותיה ו/או חובותיה על
          פי תנאי השימוש ו/או הההסכם ו/או חלקן לאחר(ים), והמשתמש נותן את הסכמתו
          לכך מראש מראש. על תנאי השימוש ו/או ההסכם ועל כל עניין הקשור והנוגע בהם
          והנובע מהם, לרבות, מבלי לפגוע בכלליות האמור לעיל, פירושם ו/או ביצועם
          ו/או הפרתם ו/או תוקפם ו/או חוקיותם ו/או ביטולם וכיו”ב, יחולו דיני
          מדינת ישראל. לבית המשפט המוסמך בעיר תל אביב – יפו מוקנית סמכות שיפוט
          ייחודית ובלעדית לדון בתנאי השימוש ו/או בהסכם ובכל עניין הקשור והנוגע
          בהם והנובע מהם, לרבות, מבלי לפגוע בכלליות האמור לעיל, פירושם ו/או
          ביצועם ו/או הפרתם ו/או תוקפם ו/או חוקיותם ו/או ביטולם וכיו”ב.
        </p>
      </div>
    </>
  );
};

export default TermOfUse;
