import { Fade } from "react-reveal";
import React from "react";
import aboutImage from "../../assets/images/about/phoneandmac.jpeg";
import waschedule from "../../assets/images/about/waschedule.png";
import wainvoice from "../../assets/images/about/wainvoice.png";
import manoiwa from "../../assets/images/about/manoiwa.png";

const AboutUs = () => {
  return (
    <>
      {/* Section for the main about image */}
      <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
        <div className="container">
          <div className="row align-items-center">
            <Fade left>
              <div className="col-lg-6">
                <div className="image-area sc-md-mb-150">
                  <div className="sc-about-image">
                    <img
                      className="about-image"
                      src={aboutImage}
                      alt="aboutImage"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Fade>
            {/* <Fade right>
              <div className="col-lg-6">
                <div className="sc-about-item sc-pl-100">
                  <div className="sc-heading-area sc-mb-30 sc-sm-mb-15">
                    <h2
                      className="heading-title"
                      style={{
                        fontSize: "45px",
                        fontWeight: "bold",
                        justifyContent: "center",
                      }}
                    >
                      כל היכולות בפלטפורמה אחת
                    </h2>
                  </div>
                </div>
              </div>
            </Fade> */}
          </div>
        </div>
      </section>

      {/* Section title for WhatsApp images */}
      <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-10">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <Fade left>
              <div className="col-12 text-center">
                <div className="sc-about-item">
                  <div className="sc-heading-area">
                    <h2
                      className="heading-title"
                      style={{
                        fontSize: "45px",
                        fontWeight: "bold",
                        textAlign: "center", // Center horizontally
                        marginBottom: "-80px", // Space between title and images
                      }}
                    >
                      אוטמציות בוואטסאפ
                    </h2>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      {/* Section for the WhatsApp-related images displayed in the same row or stacked on mobile */}
      <section className="sc-about-area gray-bg sc-pt-140 sc-pb-180 sc-md-pt-80 sc-md-pb-70">
        <div className="container">
          <div className="row align-items-center">
            <Fade left>
              <div className="col-12">
                <div
                  className="image-area"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {/* WhatsApp-related images in the same row or stacked vertically on mobile */}
                  <div
                    className="sc-about-image"
                    style={{
                      flex: "1",
                      marginBottom: "20px",
                      maxWidth: "300px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      className="about-image"
                      src={waschedule}
                      alt="waschedule"
                    />
                  </div>

                  <div
                    className="sc-about-image"
                    style={{
                      flex: "1",
                      marginBottom: "20px",
                      maxWidth: "300px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      className="about-image"
                      src={wainvoice}
                      alt="wainvoice"
                    />
                  </div>

                  <div
                    className="sc-about-image"
                    style={{
                      flex: "1",
                      marginBottom: "20px",
                      maxWidth: "300px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      className="about-image"
                      src={manoiwa}
                      alt="manoiwa"
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>

      {/* Inline style media query to handle mobile layout */}
      <style>
        {`
          @media (max-width: 768px) {
            .image-area {
              flex-direction: column !important; /* Stack the images vertically on mobile */
              align-items: center;
            }
            .sc-about-image {
              width: 100% !important; /* Ensure images take full width */
              max-width: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default AboutUs;
