import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import testImage from "../../../assets/images/testimonials/test-img.png";
import groupImage from "../../../assets/images/testimonials/orenalon.jpeg";
import groupImage1 from "../../../assets/images/testimonials/group_img1.png";
import groupImage2 from "../../../assets/images/testimonials/group_img2.png";
import groupImage3 from "../../../assets/images/testimonials/group_img3.png";
import groupImage4 from "../../../assets/images/testimonials/group_img4.png";
import groupImage5 from "../../../assets/images/testimonials/ohad.jpg";
import groupImage6 from "../../../assets/images/testimonials/group_img5.jpg";
import groupImage7 from "../../../assets/images/testimonials/didi.jpg";

const Testimonial = () => {
  const CustomRightArrow = ({ onClick }) => {
    return (
      <button className="commonArrow arrowRight" onClick={() => onClick()}>
        <i className="ri-arrow-right-line"></i>
      </button>
    );
  };
  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button className="commonArrow arrowLeft" onClick={() => onClick()}>
        <i className="ri-arrow-left-line"></i>
      </button>
    );
  };

  return (
    <>
      <section className="sc-testimonial-area sc-pt-125 sc-pb-120 sc-md-pt-60 sc-md-pb-60">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="sc-heading-area text-center">
                <h2 className="heading-title">לקוחות מספרים עלינו</h2>
              </div>
            </div>
          </div>
          <div className="home-testimonial-wrapper">
            <Carousel
              className="home-testimonial"
              infinite={true}
              draggable={true}
              pauseOnHover={true}
              slidesToSlide={1}
              swipeable={true}
              autoPlaySpeed={3000}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 992,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 991,
                    min: 577,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 576,
                    min: 1,
                  },
                  items: 1,
                },
              }}
            >
              {/* <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  התוכנה מאוד קלה לשימוש ומסייעת לנו לארגן את הלו"ז, לנהל את
                  הלקוחות שלנו ביעילות רבה יותר ולשפר את השירות שאנו מספקים. היא
                  מסייעת לנו לעקוב אחרי הזמנות, טיפולים ולהתעדכן במלאי בקלות
                </div>
                <div className="testimonial-image text-center">
                  <img src={testImage} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <Link to="#">Jenifer Aston</Link>
                  </h5>
                  <p className="designation ">Product Designer</p>
                </div>
              </div> */}
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  התוכנה הזו שינתה לחלוטין את אופן בו אנו מנהלים את העסק שלנו.
                  היא מציעה לנו פתרונות מתקדמים לניהול הלקוחות, המלאי והטיפולים.
                  כמו כן, השירות לקוחות הוא מדהים ואנו תמיד מקבלים תמיכה מקצועית
                  ומהירה כאשר נדרשת
                </div>
                <div>
                  <img
                    src={groupImage}
                    width="82"
                    height="82"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>אורן אלון</p>
                  </h5>
                  <p className="designation ">מעצב שיער ראשון לציון</p>
                </div>
              </div>
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  התוכנה הזו יצרה עבורנו חווית עבודה חדשה ומתקדמת. אנו יכולים
                  לשלב את כל התהליכים בתוך מערכת אחת, מניהול הלקוחות ועד לשילוב
                  של תמונות ותיקים. כל זה מקל עלינו להתמקד במטרות העסק ולהעניק
                  שירות מעולה ללקוחותינו
                </div>
                <div className="testimonial-image text-center">
                  <img src={groupImage5} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>אוהד אברהם</p>
                  </h5>
                  <p className="designation ">מעצב שיער נס ציונה</p>
                </div>
              </div>
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  השירות לקוחות של החברה הוא מעולה. תמיד קיים צוות זמין לעזור
                  בכל שאלה או בעיה שצצה במהלך השימוש בתוכנה
                </div>
                <div className="testimonial-image text-center">
                  <img src={groupImage2} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>אושרי זגורי</p>
                  </h5>
                  <p className="designation ">מעצב שיער קרית גת</p>
                </div>
              </div>
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  התוכנה מסייעת לנו לשמור על יחסים טובים עם הלקוחות שלנו באמצעות
                  מעקב אישי ותזכורות על מנת לשלב את הטיפולים והשירותים הנדרשים
                </div>
                <div className="testimonial-image text-center">
                  <img src={groupImage3} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>מספרת דודו ורועי</p>
                  </h5>
                  <p className="designation ">עיצוב שיער</p>
                </div>
              </div>
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  התמיכה הטכנית של החברה היא מדהימה. תמיד יש צוות מוכן לעזור בכל
                  בעיה או שאלה שיש לנו, והם תמיד מספקים פתרונות מהירים ויעילים
                </div>
                <div className="testimonial-image text-center">
                  <img src={groupImage4} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>מעיין ניילס</p>
                  </h5>
                  <p className="designation ">מכון יופי אילת</p>
                </div>
              </div>
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  ממש אהבנו את הממשק הידידותי והפשוט של התוכנה. אף אחד מהצוות
                  אינו נדרש להכשרה מיוחדת כדי להשתמש בה. היא נגישה וקלה לשימוש,
                  וזה נותן לנו יתרון גדול בזמן ובמשאבים
                </div>
                <div className="testimonial-image text-center">
                  <img src={groupImage6} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>מלי לביא</p>
                  </h5>
                  <p className="designation ">מכון יופי מעלה אדומים</p>
                </div>
              </div>
              <div className="sc-testimonial-item">
                <div className="quote-icon quote-icon_1">
                  <i className="ri-double-quotes-l"></i>
                </div>
                <div className="description">
                  כל התהליך החל מהתזמון ועד לחשבוניות נעשה בצורה יעילה וקלה עם
                  התוכנה שלכם. זה מקל עלינו הרבה ומאפשר לנו להתמקד באספקת השירות
                  הטוב ביותר ללקוחותינו
                </div>
                <div className="testimonial-image text-center">
                  <img src={groupImage7} alt="TestimonialImage" />
                </div>
                <div className="test-person-wrap text-center">
                  <h5 className="test-title sc-mb-0">
                    <p>עדי אלעזרא</p>
                  </h5>
                  <p className="designation ">מכון יופי ראש פינה</p>
                </div>
              </div>
            </Carousel>
          </div>
          <div className="test_left_imgage">
            <img
              src={groupImage}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div>

          <div className="test_left_imgage1">
            <img
              src={groupImage5}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div>

          <div className="test_left_imgage2">
            <img
              src={groupImage7}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="test_right_imgage">
            <img
              src={groupImage6}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="test_right_imgage1">
            <img
              src={groupImage4}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div className="test_right_imgage2">
            <img
              src={groupImage2}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div>
          {/* <div className="test_right_imgage3">
            <img
              src={groupImage3}
              width="82"
              height="82"
              alt="groupImage"
              style={{ borderRadius: "50%" }}
            />
          </div> */}


          {/* <div className="test_left_imgage2">
            <img src={groupImage2} width="82" height="82" alt="groupImage2" />
          </div>
          <div className="test_right_imgage">
            <img src={groupImage3} width="82" height="82" alt="groupImage3" />
          </div>
          <div className="test_right_imgage1">
            <img src={groupImage4} width="60" height="60" alt="groupImage4" />
          </div> */}
          {/* <div className="test_right_imgage2">
            <img src={groupImage5} width="82" height="82" alt="groupImage5" />
          </div> */}

          {/*  */}
        </div>
      </section>
    </>
  );
};

export default Testimonial;
