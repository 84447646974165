// import React, { useState } from "react";

// import { HashLink as Link } from "react-router-hash-link";
// import MenuItems from "./MenuItems";
// import OffCanvasMenu from "./OffCanvasMenu";
// import logo from "../../assets/images/logoRTLx.png";
// import logoDark from "../../assets/images/logo-dark.png";

// const Header = (props) => {
//   const {
//     parentMenu,
//     headerClass,
//     headerNormalLogo,
//     headerStickyLogo,
//     signUpBtn,
//   } = props;

//   const [stickyHeader, setStickyHeader] = useState(false);
//   let sticky = () => {
//     if (window.scrollY > 150) {
//       setStickyHeader(true);
//     } else {
//       setStickyHeader(false);
//     }
//   };
//   window.addEventListener("scroll", sticky);

//   return (
//     <>
//       <header
//         className={
//           stickyHeader ? headerClass + " sc-header-sticky" : headerClass
//         }
//         id="sc-header-sticky"
//       >
//         <div className="container-fluid">
//           <div className="row align-items-center justify-content-between">
//             {/* OffCanvas Area  */}
//             <div className="col-lg-4 col-md-8 col-sm-9">
//               <div className="header-btn-area p-z-idex d-flex align-items-center justify-content-center justify-content-sm-start">
//                 <div className="header-list">
//                   <ul className="d-flex ">
//                     <li className="canvas sc-pr-20 sc-sm-pr-10 text-white d-lg-none">
//                       <OffCanvasMenu></OffCanvasMenu>
//                     </li>
//                   </ul>
//                 </div>
//                 <div className="header-btn2 sc-pl-30 sc-sm-pl-20">
//                   <a
//                     href="https://admin.beautyplus.cloud/login"
//                     className={signUpBtn ? signUpBtn : "sign-in-btn"}
//                   >
//                     <span>
//                       <i className="ri-lock-line"> </i>התחברות
//                     </span>
//                   </a>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-8 col-md-4 col-sm-3">
//               <div className="sc-header-left-wraper d-flex align-items-center  justify-content-sm-end">
//                 <div className="sc-main-menu d-lg-block d-none ">
//                   <ul className="main-menu">
//                     <MenuItems parentMenu={parentMenu} />
//                   </ul>
//                 </div>
//                 <div className="sc-main-logo sc-mr-60 sc-md-mr-30 text-center text-sm-start">
//                   <div className="default-logo logo-area">
//                     <Link to="/#">
//                       <img
//                         src={headerNormalLogo ? headerNormalLogo : logo}
//                         alt="logo"
//                       />
//                     </Link>
//                   </div>
//                   <div className="sticky-logo logo-area">
//                     <Link to="/#">
//                       <img
//                         src={headerStickyLogo ? headerStickyLogo : logoDark}
//                         alt="logo"
//                       />
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//     </>
//   );
// };

// export default Header;

import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import MenuItems from "./MenuItems";
import OffCanvasMenu from "./OffCanvasMenu";
import logo from "../../assets/images/logoRTLx.png";
import logoDark from "../../assets/images/logo-dark.png";

const Header = (props) => {
  const {
    parentMenu,
    headerClass,
    headerNormalLogo,
    headerStickyLogo,
    signUpBtn,
  } = props;

  const [isLaptop, setIsLaptop] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);

  // Check if the device is a laptop
  useEffect(() => {
    const isLaptopDevice = () => {
      return window.innerWidth > 1024; // Example threshold for laptop screen width
    };

    setIsLaptop(isLaptopDevice());

    const handleResize = () => {
      setIsLaptop(isLaptopDevice());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle sticky header
  useEffect(() => {
    const sticky = () => {
      if (window.scrollY > 150) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    };

    window.addEventListener("scroll", sticky);

    return () => {
      window.removeEventListener("scroll", sticky);
    };
  }, []);

  return (
    <>
      <header
        className={
          stickyHeader ? headerClass + " sc-header-sticky" : headerClass
        }
        id="sc-header-sticky"
      >
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            {/* OffCanvas Area  */}
            <div className="col-lg-4 col-md-8 col-sm-9">
              <div className="header-btn-area p-z-idex d-flex align-items-center justify-content-center justify-content-sm-start">
                <div className="header-list">
                  <ul className="d-flex ">
                    <li className="canvas sc-pr-20 sc-sm-pr-10 text-white d-lg-none">
                      <OffCanvasMenu></OffCanvasMenu>
                    </li>
                  </ul>
                </div>
                {isLaptop && (
                  <div className="header-btn2 sc-pl-30 sc-sm-pl-20">
                    <a
                      href="https://admin.beautyplus.cloud/login"
                      className={signUpBtn ? signUpBtn : "sign-in-btn"}
                    >
                      <span>
                        <i className="ri-lock-line"> </i>התחברות
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-8 col-md-4 col-sm-3">
              <div className="sc-header-left-wraper d-flex align-items-center  justify-content-sm-end">
                <div className="sc-main-menu d-lg-block d-none ">
                  <ul className="main-menu">
                    <MenuItems parentMenu={parentMenu} />
                  </ul>
                </div>
                <div className="sc-main-logo sc-mr-60 sc-md-mr-30 text-center text-sm-start">
                  <div className="default-logo logo-area">
                    <Link to="/#">
                      <img
                        src={headerNormalLogo ? headerNormalLogo : logo}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="sticky-logo logo-area">
                    <Link to="/#">
                      <img
                        src={headerStickyLogo ? headerStickyLogo : logoDark}
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
